import { defineStore } from 'pinia';
import {
  getTaskById,
  getMyTasksByQuery,
  getTaskShareRecipients,
} from '@/service/task.service';
import { getPmScheduleById } from '@/service/pm-schedule.service';
import dayjs from 'dayjs';

export const useTaskStore = defineStore('task', {
  state: () => ({
    myTasksData: {},
    selectedTask: {},
    selectedTaskPmSchedule: {},
    selectedTaskShareRecipients: [],
    indexOfSelectedTask: null,
    myTasksSort: {
      orderBy: 'score',
      orderDirection: 'DESC',
    },
  }),
  actions: {
    async getMyTasks({ query, isFirstPage }) {
      const taskData = await getMyTasksByQuery({
        ...query,
      });
      if (isFirstPage) {
        this.myTasksData = taskData;
      } else {
        this.myTasksData.data.push(...taskData.data);
      }
    },
    async getTaskDetails(taskId) {
      const task = await getTaskById(taskId);
      this.selectedTask = task;
      const taskShareContacts = await getTaskShareRecipients(taskId);
      const { formattedContacts } = await this.getFormattedContactData(
        taskShareContacts,
      );
      this.selectedTaskShareRecipients = formattedContacts;
      if (task.request.requestType === 'Preventative') {
        const pmSchedule = await getPmScheduleById(
          task.request.pm.pmScheduleId,
        );
        this.selectedTaskPmSchedule = pmSchedule;
      }
    },
    setSelectedTaskIndex(index) {
      this.indexOfSelectedTask = index;
    },
    getFormattedContactData(contacts) {
      function getOOONote(oooStartDate, oooEndDate) {
        return oooStartDate &&
          dayjs().isBetween(oooStartDate, oooEndDate, 'day', '[]')
          ? '<b>OOO</b> - '
          : '';
      }
      const formattedContacts = [];
      if (contacts.locationContacts.length) {
        formattedContacts.push({ header: 'Location' }, { divider: true });
        contacts.locationContacts.forEach((it) => {
          it.group = 'location';
          it.isOoo = getOOONote(it.oooStartDate, it.oooEndDate);
        });
        formattedContacts.push(...contacts.locationContacts);
      }
      if (contacts.customerContacts.length) {
        formattedContacts.push({ header: 'Corporate' }, { divider: true });
        contacts.customerContacts.forEach((it) => {
          it.group = 'corporate';
          it.isOoo = getOOONote(it.oooStartDate, it.oooEndDate);
        });
        formattedContacts.push(...contacts.customerContacts);
      }
      if (contacts.vendorContacts.length) {
        formattedContacts.push({ header: 'Vendor' }, { divider: true });
        contacts.vendorContacts.forEach((it) => {
          it.group = 'vendor';
          it.isOoo = getOOONote(it.oooStartDate, it.oooEndDate);
        });
        formattedContacts.push(...contacts.vendorContacts);
      }
      return {
        formattedContacts,
        contacts,
      };
    },
  },
});
