import analyticsService from './analytics.service';
import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  requestDetailById: (id) => `requests/${id}`,
  requestPickerView: () => 'requests/summaries',
  requestSearch: () => 'requests/search',
  requestPickerLocationList: () => 'locations/viewableRequestLocations',
  requestPickerVendorList: () => 'vendors/viewableRequestVendors',
  saveRequest: () => 'requests',
  requestAttachment: (id) => `requests/${id}/attachments`,
  requestAttachmentById: (requestId, attachmentId) =>
    `requests/${requestId}/attachments/${attachmentId}`,
  requestActivities: (id) => `requests/${id}/activities`,
  requestNotesBy: (id) => `requests/${id}/notes`,
  requestComplete: (id) => `requests/${id}/complete`,
  pmChoreComplete: () => 'requests/completeChore',
  pmServiceWindow: (id) => `requests/${id}/serviceWindow`,
  requestInvoiceSummaries: () => 'requests/invoice-summaries',
  downloadRequestInvoiceZip: () => 'requests/invoice-summaries/zip',
  downloadRequestInvoiceCsv: () => 'requests/invoice-summaries/csv',
  vendorCheckin: (id) => `requests/${id}/checkin`,
  isVendorCheckedIn: (id) => `requests/${id}/checkedIn`,
  requestStats: () => 'requests/stats',
  requestType: (id) => `requests/${id}/requestType`,
  subStatus: (requestId) => `requests/${requestId}/sub-status`,
  taggableUsers: (requestId) => `requests/${requestId}/taggableUsers`,
  requestProofOfValueMetrics: (requestId) =>
    `requests/${requestId}/proofOfValueMetrics`,
};

/**
 * Get a list of requests for request picker
 * @param {object} query
 * @returns {Array}
 */
export async function getRequestPickerView(query) {
  try {
    const res = await esApiV3.get(api.requestPickerView(), {
      params: query,
    });
    return res?.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get request picker view',
    );
  }
}

/**
 * Query paginated requests
 * @param {object} query
 * @returns {Array}
 */
export async function searchPaginatedRequests(query) {
  try {
    const res = await esApiV3.get(api.requestSearch(), {
      params: query,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get request picker view',
    );
  }
}

export async function getRequestPickerViewLocations() {
  try {
    const res = await esApiV3.get(api.requestPickerLocationList());
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get request picker view locations',
    );
  }
}

export async function getRequestPickerViewVendors() {
  try {
    const res = await esApiV3.get(api.requestPickerVendorList());
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get request picker view vendors',
    );
  }
}

/**
 * Get a single request by id
 * @param {string} id
 * @returns {object}
 */
export async function getRequestDetailById(id) {
  try {
    const res = await esApiV3.get(api.requestDetailById(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      `An error occurred while trying to get request by id: ${id}`,
    );
  }
}

export async function markPMChoreComplete(
  pmRequestDetailAssetId,
  choreTypeId,
  completed,
) {
  const payload = {
    pmRequestDetailAssetId,
    choreTypeId,
    completed,
  };
  try {
    const res = await esApiV3.post(api.pmChoreComplete(), payload);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to mark chore complete',
    );
  }
}

function toCreateRequestDto(newRequest) {
  return {
    contactIds: newRequest.contact.id ? [newRequest.contact.id] : [],
    requestPriority: newRequest.priority,
    assetId: newRequest.asset.id,
    incidentDescription: newRequest.incidentDescription,
    assignedToVendorId: newRequest.vendor,
    symptoms: newRequest.symptoms,
    isOtApproved: newRequest.isOtApproved,
    emergencyCallbackPhoneNumber: newRequest.emergencyCallbackPhoneNumber,
  };
}

export async function saveNewRequest(newRequest) {
  try {
    const payload = toCreateRequestDto(newRequest);
    const res = await esApiV3.post(api.saveRequest(), payload);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to save new request',
    );
  }
}

export async function updateRequest(id, requestUpdate) {
  try {
    const res = await esApiV3.patch(api.requestDetailById(id), requestUpdate);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to update request',
    );
  }
}

export async function completeRequest(id, completeRequestData) {
  try {
    const res = await esApiV3.patch(
      api.requestComplete(id),
      completeRequestData,
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to complete request',
    );
  }
}

export async function updatePmServiceWindow(id, startDate) {
  try {
    const res = await esApiV3.patch(api.pmServiceWindow(id), {
      startDate,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to update request',
    );
  }
}

export async function getActivitiesForRequest(id) {
  try {
    const res = await esApiV3.get(api.requestActivities(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get activities',
    );
  }
}

export async function getProofOfValueMetricsForRequest(id) {
  try {
    const res = await esApiV3.get(api.requestProofOfValueMetrics(id));
    const { data } = res;

    return {
      potentialSavings: {
        pendingTroubleshootingProjectedSavingsUsd:
          data.pendingTroubleshootingProjectedSavingsUsd,
        quoteProjectedSavingsUsd: data.quoteProjectedSavingsUsd,
        assetOverFiftyPercentOfSpendProjectedSavingsUsd:
          data.assetOverFiftyPercentOfSpendProjectedSavingsUsd,
        pendingWarrantyProjectedSavingsUsd:
          data.pendingWarrantyProjectedSavingsUsd,
      },
      actualSavings: {
        assetsFixedUnderWarrantyProjectedSavingsUsd:
          data.assetsFixedUnderWarrantyProjectedSavingsUsd,
        dispatchAvoidanceProjectedSavingsUsd:
          data.dispatchAvoidanceProjectedSavingsUsd,
        quoteSavedUsd: data.quoteSavedUsd,
        replacedAssetSavingsUsd: data.replacedAssetSavingsUsd,
      },
    };
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while getting request proof of value metrics',
    );
  }
}

export async function getAttachmentsForRequest(id) {
  try {
    const res = await esApiV3.get(api.requestAttachment(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get attachments',
    );
  }
}

/**
 * @param {string} id
 * @param {any} attachment
 * @param {string} attachmentType
 * @param {string | undefined} taskId
 * @returns
 */
export async function uploadAttachment(
  id,
  attachment,
  attachmentType,
  taskId,
  typeMetadata,
) {
  const formData = new FormData();
  formData.append('file', attachment);
  formData.append('attachmentType', attachmentType);
  formData.append('requestId', id);
  if (taskId != null) formData.append('taskId', taskId);
  if (typeMetadata != null) {
    formData.append('typeMetadata', JSON.stringify(typeMetadata));
  }
  try {
    const res = await esApiV3.post(api.requestAttachment(id), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return [res.data, res.status];
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to upload attachment',
    );
  }
}

/**
 *
 * @param {string} requestId
 * @param {string} attachmentId
 * @returns {object}
 */
export async function deleteAttachmentById(requestId, attachmentId) {
  try {
    const res = await esApiV3.delete(
      api.requestAttachmentById(requestId, attachmentId),
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to delete attachment',
    );
  }
}

export async function updateAttachmentById(
  requestId,
  attachmentId,
  attachment,
) {
  try {
    const res = await esApiV3.patch(
      api.requestAttachmentById(requestId, attachmentId),
      attachment,
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to update attachment',
    );
  }
}

export async function addNote(requestId, note) {
  try {
    const res = await esApiV3.post(api.requestNotesBy(requestId), note);

    analyticsService.logEvent('Note Added', {
      Text: note.text,
      'Action Required': note.actionRequired,
    });

    return res.data;
  } catch (ex) {
    exception.showCustomError(ex, 'An error occurred while trying to add note');
  }
}

export async function requestInvoiceSummaries(query) {
  try {
    const res = await esApiV3.get(api.requestInvoiceSummaries(), {
      params: query,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get invoice and request data',
    );
  }
}

export async function downloadRequestInvoiceZip(query) {
  try {
    const res = await esApiV3.get(api.downloadRequestInvoiceZip(), {
      params: query,
      responseType: 'blob',
    });
    return res;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to download invoice zip file',
    );
  }
}

export async function downloadRequestInvoiceCsv(query) {
  try {
    const res = await esApiV3.get(api.downloadRequestInvoiceCsv(), {
      params: query,
      responseType: 'blob',
    });
    return res;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to download invoice csv file',
    );
  }
}

export async function performVendorCheckin(requestId) {
  try {
    const res = await esApiV3.post(api.vendorCheckin(requestId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while checking into the request',
    );
  }
}

export async function isVendorCheckedInToRequest(requestId) {
  try {
    const res = await esApiV3.get(api.isVendorCheckedIn(requestId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while checking into the request',
    );
  }
}

/**
 * Get stats of requests for at a glance page
 * @param {object} query
 * @returns {object}
 */
export async function getAtAGlanceStats(query) {
  try {
    const res = await esApiV3.get(api.requestStats(), {
      params: query,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get request stats',
    );
  }
}

export async function getRequestType(id) {
  try {
    const res = await esApiV3.get(api.requestType(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get request type',
    );
  }
}

export async function getRequestSubStatus(requestId) {
  try {
    const res = await esApiV3.get(api.subStatus(requestId));
    return res.data;
  } catch (ex) {
    console.error(ex);
    return null;
  }
}

export async function getTaggableUsers(requestId) {
  try {
    const res = await esApiV3.get(api.taggableUsers(requestId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get taggable users',
    );
  }
}
