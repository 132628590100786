import ovenIcon from '@/assets/86_Repairs-Icon-Oven.png';
import boltIcon from '@/assets/86_Repairs-Icon-Screw_Bolt.png';
import primaryLogo from '@/assets/86repairs-primary-logo-horizontal.png';
import whiteLogo from '@/assets/86repairs-white-horizontal.png';
import manufacturersIcon from '@/assets/Icon_Manufacturers.png';
import timeIcon from '@/assets/Icon_Time.png';
import troubleshootingIcon from '@/assets/Icon_Troubleshooting.png';
import appMockupImage from '@/assets/app_mockup.png';
import appStoreBadge from '@/assets/app-store-badge.png';
import googlePlayBadge from '@/assets/google-play-badge.png';
import placeholder from '@/assets/material.jpg';
import ptFavicon from '@/assets/pt-favicon.ico';
import favicon from '@/assets/favicon.ico';
import myService360LogoWhite from '@/assets/MyService360_Logo_white.png';
import myService360Logo from '@/assets/MyService360_Logo_rgb.png';
import ptTechIcon from '@/assets/pt-tech.png';
import ptGearIcon from '@/assets/pt-gear.png';
import ptTimeIcon from '@/assets/pt-clock.png';

const module = {
  namespaced: true,
};

module.state = {
  ovenIcon,
  boltIcon,
  primaryLogo,
  whiteLogo,
  manufacturersIcon,
  timeIcon,
  troubleshootingIcon,
  appMockupImage,
  appStoreBadge,
  googlePlayBadge,
  ptFavicon,
  favicon,
  myService360LogoWhite,
  myService360Logo,
  ptTechIcon,
  ptTimeIcon,
  ptGearIcon,
};

module.getters = {
  placeholder: (state, getters, rootState, rootGetters) => {
    const isPartsTown = rootGetters['auth/isPartsTown'];
    return isPartsTown
      ? placeholder
      : 'https://cdn.vuetifyjs.com/images/parallax/material.jpg';
  },
};

export default module;
