<template>
  <v-app :class="{ 'parts-town': isPartsTown }">
    <v-fade-transition>
      <v-overlay v-if="appLoading" color="white" opacity="1" light absolute>
        <div class="d-flex align-center justify-center flex-column">
          <v-img
            v-if="isPartsTown"
            alt="myService360 Logo"
            class="shrink mb-2"
            contain
            :src="myService360Logo"
            width="150" />
          <v-progress-circular
            color="black"
            indeterminate
            size="50"></v-progress-circular>
        </div>
      </v-overlay>
    </v-fade-transition>
    <router-view></router-view>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState(['appLoading']),
    ...mapState('staticImages', [
      'primaryLogo',
      'ptFavicon',
      'favicon',
      'myService360Logo',
    ]),
    ...mapGetters('auth', ['isPartsTown']),
  },
  mounted() {
    if (this.isPartsTown) {
      this.setPartsTownTheme();
    } else {
      this.setEightySixTheme();
    }
  },
  watch: {
    isPartsTown: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value === true) {
          this.setPartsTownTheme();
        } else {
          this.setEightySixTheme();
        }
      },
    },
    $route: {
      immediate: true,
      deep: true,
      handler(route) {
        if (route.name === 'Logout') {
          this.setPartsTownTheme();
        }
      },
    },
  },
  methods: {
    setEightySixTheme() {
      this.changeFavicon(this.favicon);
      document.title = '86 | Service Portal';
    },
    setPartsTownTheme() {
      this.$vuetify.theme.themes.light.primary = '#C81031';
      this.$vuetify.theme.themes.light.secondary = '#C81031';
      this.$vuetify.theme.themes.light.accent = '#2D2926';
      this.$vuetify.theme.themes.light.anchor = '#C81031';
      this.changeFavicon(this.ptFavicon);
      document.title = 'My Service360';
    },
    changeFavicon(src) {
      const link = document.createElement('link');
      const oldLink = document.getElementById('dynamic-favicon');
      link.id = 'dynamic-favicon';
      link.rel = 'icon';
      link.href = src;
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: effra;
  src: url(./assets/Effra_Std_Rg.ttf);
}
$font-family: 'effra';
.v-application.parts-town {
  [class*='text-'] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}
.v-card__text,
.v-card__title {
  word-break: normal;
}

.clickable {
  cursor: pointer;
}

.task-form-section {
  padding: 20px;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

.jwt-task {
  .task-form-section {
    background: #fff;
    border-radius: 5px;
    margin-top: 20px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
}
</style>
