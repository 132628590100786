const config = {
  env: import.meta.env.NODE_ENV,
  mode: import.meta.env.VITE_MODE,
  esApiUrl: import.meta.env.VITE_ES_API_URL,
  ptApiUrl: import.meta.env.VITE_PT_API_URL,
  apiUrl: '',
  esApiToken: import.meta.env.VITE_ES_API_TOKEN,
  sspUrl: import.meta.env.VITE_SSP_URL,
  version: import.meta.env.VITE_VERSION || 'unknown',
  sentryVersion: import.meta.env.SENTRY_VERSION,
  amplitudeKey: import.meta.env.VITE_AMPLITUDE_KEY,
  amplitudeEnabled: import.meta.env.VITE_AMPLITUDE_KEY != null,
  adobeEmbedKey: import.meta.env.VITE_ADOBE_EMBED_KEY,
  recaptchaKey: import.meta.env.VITE_RECAPTCHA_KEY,
};

const isPartsTown = window.location.hostname.includes('partstown.com');

config.apiUrl = isPartsTown ? config.ptApiUrl : config.esApiUrl;

export default config;
